import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LoggerService } from '../service/logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

	constructor(private authService: AuthService,
				private router: Router,
				private logger: LoggerService) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.checkLogin(state.url);
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(next, state);
	}
	
	canLoad(
		route: Route,
		segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
		let url = `/${route.path}`;
		return this.checkLogin(url);
	}

	checkLogin(url: string): boolean {
		if (this.authService.isLoggedIn() && !this.authService.getUserData().expired) { return true; }

		// this.logger.i("auth.guard: " + "checklogin");
		// Store the attempted URL for redirecting
		this.authService.redirectUrl = url;

		// Create a dummy session id
		let sessionId = 123456789;

		// Set our navigation extras object
		// that contains our global query params and fragment
		let navigationExtras: NavigationExtras = {
			queryParams: { 'session_id': sessionId },
			fragment: 'anchor'
		};

		// Navigate to the login page with extras
		this.router.navigate(['auth/login'], navigationExtras);
		return false;
	}

}
